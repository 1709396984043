import { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom' 
import axios from 'axios'
import Swal from 'sweetalert2'
import { reAssignAddress, cutSubDistrict, cutProvince, cutDistrict, formatName } from '../function/stringUtils'
import { getToken } from '../services/authorize'

const RegistePrathomComponent = () => {
  // decalre Referent for using input field
  const inputStudentIdCard = useRef(null)
  const inputRegisterIdCard = useRef(null)
  const inputDadIdCard = useRef(null)
  const inputMomIdCard = useRef(null)

  const navigate = useNavigate()

  const [state, setState] = useState({
    parentIdCard: "",
    parentName: "",
    parentBirthdate: "",
    parentRelation: "",
    parentAddress: "",
    parentSubDistrict: "",
    parentDistrict: "",
    parentProvince: "",
    parentZipcode: "",
    parentJob: "",
    parentWorkPlace: "",
    parentIncome: "",
    parentPhone: "",
    regName: "",
    regChooseType: "",
    regChooseClass: "",
    regIdCard: "",
    regBirthdate: "",
    regNationality: "ไทย",
    regRace: "ไทย",
    regReligion: "อิสลาม",
    regBlood: "",
    regHomeId: "",
    regAddress: "",
    regSubDistrict:"",
    regDistrict: "",
    regProvince: "",
    regZipcode: "",
    regAllChild: "",
    regChildOrder: "",
    regChildStudying: "",
    regSyndrome: "ไม่มี",
    regDrug: "ไม่มี",
    regFood: "ไม่มี",
    regPrevAcademicSchool: "",
    regPrevAcademicSubdistrict: "",
    regPrevAcademicDistrict: "",
    regPrevAcademicProvince: "",
    dadName: "",
    dadIdCard: "",
    dadBirthdate: "",
    dadAddress: "",
    dadSubDistrict: "",
    dadDistrict: "",
    dadProvince: "",
    dadZipcode: "",
    dadNationality: "ไทย",
    dadRace: "ไทย",
    dadStatus: "",
    dadJob: "",
    dadWorkPlace: "",
    dadIncome: "",
    dadPhone: "",
    momName: "",
    momIdCard: "",
    momBirthdate: "",
    momAddress: "",
    momSubDistrict: "",
    momDistrict: "",
    momProvince: "",
    momZipcode: "",
    momNationality: "ไทย",
    momRace: "ไทย",
    momStatus: "",
    momJob: "",
    momWorkPlace: "",
    momIncome: "",
    momPhone: "",
    parentStatus: "",
    emerFirstname: "",
    emerLastname: "",
    emerPhone: ""
  })

  // destructuring
  const {
    parentIdCard,
    parentName,
    parentBirthdate,
    parentRelationType,
    parentRelation,
    parentAddress,
    parentSubDistrict,
    parentDistrict,
    parentProvince,
    parentZipcode,
    parentJob,
    parentWorkPlace,
    parentIncome,
    parentPhone,
    regName,
    regChooseType,
    regChooseClass,
    regIdCard,
    regBirthdate,
    regNationality,
    regRace,
    regReligion,
    regBlood,
    regHomeId,
    regAddress,
    regSubDistrict,
    regDistrict,
    regProvince,
    regZipcode,
    regAllChild,
    regChildOrder,
    regChildStudying,
    regSyndrome,
    regDrug,
    regFood,
    regPrevAcademicSchool,
    regPrevAcademicSubdistrict,
    regPrevAcademicDistrict,
    regPrevAcademicProvince,
    dadName,
    dadIdCard,
    dadBirthdate,
    dadAddress,
    dadSubDistrict,
    dadDistrict,
    dadProvince,
    dadZipcode,
    dadNationality,
    dadRace,
    dadStatus,
    dadJob,
    dadWorkPlace,
    dadIncome,
    dadPhone,
    momName,
    momIdCard,
    momBirthdate,
    momAddress,
    momSubDistrict,
    momDistrict,
    momProvince,
    momZipcode,
    momNationality,
    momRace,
    momStatus,
    momJob,
    momWorkPlace,
    momIncome,
    momPhone,
    parentStatus,
    emerFirstname,
    emerLastname,
    emerPhone
  } = state

  //set value to state
  const inputValue = name => event => {
    setState({...state, [name]:event.target.value})
  }

  // check if radio otherParent is checked or no
  const [isOtherParentDisabled, setIsOtherParentDisabled] = useState(true)
  const [isRegisAcademic, setIsRegisAcademic] = useState(true)

  useEffect(() => {
    if (parentRelationType === 'other') {
      setState({...state, parentRelation: ''})
      setIsOtherParentDisabled(false)
    } else if (parentRelationType === 'dad') {
      setState({
        ...state,
        parentRelation: 'บิดา',
        dadIdCard: parentIdCard,
        dadName: parentName,
        dadBirthdate: parentBirthdate,
        dadAddress: parentAddress,
        dadSubDistrict: parentSubDistrict,
        dadDistrict: parentDistrict,
        dadProvince: parentProvince,
        dadZipcode: parentZipcode,
        dadPhone: parentPhone,
        dadJob: parentJob,
        dadWorkPlace: parentWorkPlace,
        dadIncome: parentIncome,
        // set mom info to null if dad is chosen
        momIdCard: '',
        momName: '',
        momBirthdate: '',
        momAddress: '',
        momSubDistrict: '',
        momDistrict: '',
        momProvince: '',
        momZipcode: '',
        momPhone: '',
        momJob: '',
        momWorkPlace: '',
        momIncome: ''
      })
      setIsOtherParentDisabled(true)
    } else if (parentRelationType === 'mom') {
      setState({
        ...state,
        parentRelation: 'มารดา',
        momIdCard: parentIdCard,
        momName: parentName,
        momBirthdate: parentBirthdate,
        momAddress: parentAddress,
        momSubDistrict: parentSubDistrict,
        momDistrict: parentDistrict,
        momProvince: parentProvince,
        momZipcode: parentZipcode,
        momPhone: parentPhone,
        momJob: parentJob,
        momWorkPlace: parentWorkPlace,
        momIncome: parentIncome,
        // set dad info to null of mom is chosen
        dadIdCard: '',
        dadName: '',
        dadBirthdate: '',
        dadAddress: '',
        dadSubDistrict: '',
        dadDistrict: '',
        dadProvince: '',
        dadZipcode: '',
        dadPhone: '',
        dadJob: '',
        dadWorkPlace: '',
        dadIncome: ''
      })
      setIsOtherParentDisabled(true)
    }

    regChooseType !== "" ? setIsRegisAcademic(false) : setIsRegisAcademic(true)
    
  }, [
    regChooseType,
    parentRelationType,
    parentRelation,
    parentIdCard,
    parentName,
    parentBirthdate,
    parentAddress,
    parentSubDistrict,
    parentDistrict,
    parentProvince,
    parentZipcode,
    parentPhone,
    parentJob,
    parentWorkPlace,
    parentIncome
    //state
  ])

  // check user choose Anubal or Prathom school
  const showClassToChoose = () => {
    if(regChooseType === 'anubal') {
      return (
        <>
          <option value="a1">อ. 1</option>
          <option value="a2">อ. 2</option>
          <option value="a3">อ. 3</option>
        </>
      )
    } else {
      return (
        <>
          <option value="p1">ป. 1</option>
          <option value="p2">ป. 2</option>
          <option value="p3">ป. 3</option>
          <option value="p4">ป. 4</option>
          <option value="p5">ป. 5</option>
          <option value="p6">ป. 6</option>
        </>
      )
    }
  }

  // this function called after user click for pull from card
  const pullRegisterIdCard = (e) => {
    e.preventDefault()
    // setValue of Pulling data to "" before pulling
    setState({...state, regIdCard: "", regName: "", regBirthdate: "", regAddress: ""})
    Swal.fire({
      title: "ดึงข้อมูลจากบัตรผู้สมัคร",
      text: "กด OK แล้วเสียบบัตรประชาชนเข้าเครื่องอ่านบัตร หากเครื่องเสียบบัตรอยู่แล้วให้ดึงออกสักครู่แล้วเสียบใหม่อีกครั้ง",
      icon: "warning",
      didClose: () => {
        inputRegisterIdCard.current.focus();
      }
    })
  }
  const pullStudentIdCard = (e) => {
    e.preventDefault()
    // setValue of Pulling data to "" before pulling
    setState({...state, regIdCard: "", regName: "", regBirthdate: "", regAddress: ""})
    Swal.fire({
      title: "ดึงข้อมูลจากบัตรผู้สมัคร",
      text: "กด OK แล้วเสียบบัตรประชาชนเข้าเครื่องอ่านบัตร หากเครื่องเสียบบัตรอยู่แล้วให้ดึงออกสักครู่แล้วเสียบใหม่อีกครั้ง",
      icon: "warning",
      didClose: () => {
        inputStudentIdCard.current.focus();
      }
    })
  }
  const pullDadCard = (e) => {
    e.preventDefault()
    // setValue of Pulling data to "" before pulling
    setState({...state, dadIdCard: "", dadName: "", dadBirthdate: "", dadAddress: ""})
    Swal.fire({
      title: "ดึงข้อมูลจากบัตรบิดา",
      text: "กด OK แล้วเสียบบัตรประชาชนเข้าเครื่องอ่านบัตร หากเครื่องเสียบบัตรอยู่แล้วให้ดึงออกสักครู่แล้วเสียบใหม่อีกครั้ง",
      icon: "warning",
      didClose: () => {
        inputDadIdCard.current.focus();
      }
    })
  }
  const pullMomCard = (e) => {
    e.preventDefault()
    // setValue of Pulling data to "" before pulling
    setState({...state, dadIdCard: "", dadName: "", dadBirthdate: "", dadAddress: ""})
    Swal.fire({
      title: "ดึงข้อมูลจากบัตรบิดา",
      text: "กด OK แล้วเสียบบัตรประชาชนเข้าเครื่องอ่านบัตร หากเครื่องเสียบบัตรอยู่แล้วให้ดึงออกสักครู่แล้วเสียบใหม่อีกครั้ง",
      icon: "warning",
      didClose: () => {
        inputMomIdCard.current.focus();
      }
    })
  }

  // check ID Card is duplicate or not
  const checkDuplicateIdCard = () => {
    if(regIdCard.length === 13) {
      axios.post(`${process.env.REACT_APP_API}/registerMatthayom/check`, { regIdCard })
      .then(response => {

      })
      .catch(err => {
        Swal.fire({
          title: "error",
          text: err.response.data.message,
          icon: "error",
          didClose: () => {
            setState({...state, regIdCard: ""})
            inputStudentIdCard.current.focus();
          }
        })
      })
    }
  }

  const [regBy, setRegBye] = useState('')
  useEffect(() => {
    if (sessionStorage.getItem('user')) {
      // Parse the 'user' object from JSON
      const user = JSON.parse(sessionStorage.getItem('user'));
      setRegBye(user.t_name)
      // console.log(user)
    }
  }, [])

  // submit data to api 
  const submitForm = (e) => {
    e.preventDefault();
    axios.post(`${process.env.REACT_APP_API}/registerPrathom/add`,
      {
        parentIdCard,
        parentName,
        parentBirthdate,
        parentRelationType: parentRelationType || "",
        parentRelation,
        parentAddress,
        parentSubDistrict,
        parentDistrict,
        parentProvince,
        parentZipcode,
        parentJob,
        parentWorkPlace,
        parentIncome,
        parentPhone,
        regName,
        regChooseType,
        regChooseClass,
        regIdCard,
        regBirthdate,
        regNationality,
        regRace,
        regReligion,
        regBlood,
        regHomeId,
        regAddress,
        regSubDistrict,
        regDistrict,
        regProvince,
        regZipcode,
        regAllChild,
        regChildOrder,
        regChildStudying,
        regSyndrome,
        regDrug,
        regFood,
        regPrevAcademicSchool,
        regPrevAcademicSubdistrict,
        regPrevAcademicDistrict,
        regPrevAcademicProvince,
        dadName,
        dadIdCard,
        dadBirthdate,
        dadAddress,
        dadSubDistrict,
        dadDistrict,
        dadProvince,
        dadZipcode,
        dadNationality,
        dadRace,
        dadStatus,
        dadJob,
        dadWorkPlace,
        dadIncome,
        dadPhone,
        momName,
        momIdCard,
        momBirthdate,
        momAddress,
        momSubDistrict,
        momDistrict,
        momProvince,
        momZipcode,
        momNationality,
        momRace,
        momStatus,
        momJob,
        momWorkPlace,
        momIncome,
        momPhone,
        parentStatus,
        emerFirstname,
        emerLastname,
        emerPhone,
        regBy
      },
      {
        headers: {
            authorization: `Bearer ${getToken()}`
        }
      }
    ).then(response => {
        Swal.fire({
          title: response.data.message,
          text: "เลขประจำตัว " + response.data.st_idnumber,
          icon: "success",
        }).then(() => {
          navigate(`/printPrathom/${response.data.st_id}`)
        })
        //console.log(response)
      }).catch(err => {
        if (err.code === "ERR_NETWORK") {
          Swal.fire({
            title: "Network Error",
            text: "ไม่สามารถเชื่อมต่อได้ กรุณาตรวจสอบอินเทอร์เน็ต",
            icon: "error"
          })
        } else {
          Swal.fire({
            title: "error",
            text: err.response.data.message,
            icon: "error"
          })
          //console.log(err)
        }
      })
  }

  return (
    <div className='pt-4 ps-4 pe-4 mt-5 mb-4'>
      <div className='container-fluid'>
        <div className='row box-content rounded'>
          <h4 className="text-dark fw-bold">
              <i className='bi bi-person-add me-2'></i>
              สมัครนักเรียนประถม
            </h4>
            <hr className='text-dark mt-1 mb-3'/>
            <form onSubmit={submitForm}>
              <h6 className="text-light bg-secondary fw-bold p-2">- ข้อมูลผู้ขอสมัคร/ผู้ปกครอง</h6>
              <hr className='text-secondary mt-1 mb-3'/>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputRegisterIdCard" className="col-sm-2 col-form-label">เลขประจำตัวประชาชน</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" ref={inputRegisterIdCard} id="inputRegisterIdCard" value={parentIdCard} onChange={inputValue("parentIdCard")} placeholder="รหัสประจำตัวประชาชน" />
                </div>
                <div className="col-sm-6 p-2">
                  <button type="button" className="btn btn-primary" onClick={pullRegisterIdCard}><i className="bi bi-person-vcard me-2"></i>ดึงข้อมูลจากบัตรประชาชน</button>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputParentName" className="col-sm-2 col-form-label"><span className='text-danger'>*</span>ข้าพเจ้า ชื่อ</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputParentName" value={parentName} onChange={inputValue("parentName")} onBlur={(e) => formatName(e.target.value, setState, state, 'parent')} placeholder="ชื่อเต็มผู้ขอสมัคร" required/>
                </div>
                <label htmlFor="inputParentBirthdate" className="col-sm-2 col-form-label">วัน เดือน ปีเกิด</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputParentBirthdate" value={parentBirthdate} onChange={inputValue("parentBirthdate")} placeholder="เช่น 31/12/2532" />
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputParentAddress" className="col-sm-2 col-form-label">ที่อยู่</label>
                <div className="col-sm-4 p-2">
                  <textarea type="text" className="form-control" id="inputParentAddress" rows="3" value={parentAddress} onChange={inputValue("parentAddress")} onBlur={(e) => reAssignAddress(e.target.value, setState, state, 'parent')} placeholder="กรอกบ้านเลขที่ ถนน ซอย หมู่"></textarea>
                </div>
                <label htmlFor="inputParentSubDistrict" className="col-sm-2 col-form-label">ตำบล/แขวง</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputParentSubDistrict" value={parentSubDistrict} onChange={inputValue("parentSubDistrict")} onBlur={(e) => cutSubDistrict(e.target.value, setState, state, 'parent')} placeholder="ระบุตำบล หรือแขวง"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputParentDistrict" className="col-sm-2 col-form-label">อำเภอ/เขต</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputParentDistrict" value={parentDistrict} onChange={inputValue("parentDistrict")} onBlur={(e) => cutDistrict(e.target.value, setState, state, 'parent')} placeholder="ระบุ อำเภอ หรือเขต"/>
                </div>
                <label htmlFor="inputParentProvince" className="col-sm-2 col-form-label">จังหวัด</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputParentProvince" value={parentProvince} onChange={inputValue("parentProvince")} onBlur={(e) => cutProvince(e.target.value, setState, state, 'parent')} placeholder="ระบุ จังหวัด"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputParentZipcode" className="bg-light col-sm-2 col-form-label">รหัสไปรษณีย์</label>
                <div className="col-sm-4 bg-light p-2">
                  <input type="text" className="form-control" id="inputParentZipcode" value={parentZipcode} onChange={inputValue("parentZipcode")} placeholder="กรอกรหัสไปรษณีย์"/>
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor="inputParentRelation" className="bg-light col-sm-2 col-form-label">เกี่ยวข้องกับนักเรียนเป็น</label>
                <div className="btn-group col-sm-4 p-2 bg-light" role="group">
                  <input type="radio" className="btn-check" name="radio_parentType" id="radio_parentType1" value={'dad'} checked={parentRelationType === 'dad'} onChange={inputValue("parentRelationType")} autoComplete="off" />
                  <label className="btn btn-outline-primary" htmlFor="radio_parentType1">บิดา</label>

                  <input type="radio" className="btn-check" name="radio_parentType" id="radio_parentType2" value={'mom'} checked={parentRelationType === 'mom'} onChange={inputValue("parentRelationType")} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="radio_parentType2">มารดา</label>

                  <input type="radio" className="btn-check" name="radio_parentType" id="radio_parentType3" value={'other'} checked={parentRelationType === 'other'} onChange={inputValue("parentRelationType")} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="radio_parentType3">อื่นๆ</label>
                </div>
                <label htmlFor="inputParentRelation" className="col-sm-2 col-form-label bg-light" hidden={isOtherParentDisabled}>โปรดระบุ</label>
                <div className="col-sm-4 p-2 bg-light">
                  <input type="text" className="form-control" id="inputParentRelation" value={parentRelation} onChange={inputValue("parentRelation")} placeholder="ระบุความเกี่ยวข้องกับนักเรียน" hidden={isOtherParentDisabled}/>
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputParentJob' className='bg-light col-sm-2 col-form-label'>อาชีพ</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputParentJob' value={parentJob} onChange={inputValue("parentJob")} placeholder="ระบุอาชีพ"/>
                </div>
                <label htmlFor='inputParentWorkPlace' className='bg-light col-sm-2 col-form-label'>สถานที่ทำงาน</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputParentWorkPlace' value={parentWorkPlace} onChange={inputValue("parentWorkPlace")} placeholder="ระบุสถานที่ทำงาน"/>
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputParentIncome' className='bg-light col-sm-2 col-form-label'>รายได้/เดือน (บาท)</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputParentIncome' value={parentIncome} onChange={inputValue("parentIncome")} placeholder="ระบุรายได้เช่น 15000" />
                </div>
                <label htmlFor='inputParentPhone' className='bg-light col-sm-2 col-form-label'>เบอร์โทรศัพท์</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputParentPhone' value={parentPhone} onChange={inputValue("parentPhone")} placeholder="ระบุเบอรโทรศัพท์"/>
                </div>
              </div>
              <hr className='text-dark mt-1 mb-3'/>
              <h6 className="text-light bg-secondary fw-bold p-2">- ขอสมัครนักเรียน</h6>
              <hr className='text-secondary mt-1 mb-3'/>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputIdCard" className="col-sm-2 col-form-label">เลขประจำตัวประชาชน</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" ref={inputStudentIdCard} id="inputIdCard" value={regIdCard} onChange={inputValue("regIdCard")} onKeyUp={checkDuplicateIdCard} placeholder="รหัสประจำตัวประชาชน" />
                </div>
                <div className="col-sm-6 p-2">
                  <button type="button" className="btn btn-primary" onClick={pullStudentIdCard}><i className="bi bi-person-vcard me-2"></i>ดึงข้อมูลจากบัตรประชาชน</button>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputName" className="col-sm-2 col-form-label"><span className='text-danger'>*</span>นักเรียน ชื่อ</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputName" value={regName} onChange={inputValue("regName")} placeholder="ชื่อเต็มผู้สมัคร" onBlur={(e) => formatName(e.target.value, setState, state, 'reg')} required/>
                </div>
                <label htmlFor="inputBirthdate" className="col-sm-2 col-form-label">วัน เดือน ปีเกิด</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputBirthdate" value={regBirthdate} onChange={inputValue("regBirthdate")} placeholder="เช่น 31/12/2532" />
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputRegAddress" className="col-sm-2 col-form-label">ที่อยู่</label>
                <div className="col-sm-4 p-2">
                  <textarea type="text" className="form-control" id="inputRegAddress" rows="3" value={regAddress} onChange={inputValue("regAddress")} onBlur={(e) => reAssignAddress(e.target.value, setState, state, 'reg')} placeholder="กรอกบ้านเลขที่ ถนน ซอย หมู่"></textarea>
                </div>
                <label htmlFor="inputRegSubDistrict" className="col-sm-2 col-form-label">ตำบล/แขวง</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputRegSubDistrict" value={regSubDistrict} onChange={inputValue("regSubDistrict")} onBlur={(e) => cutSubDistrict(e.target.value, setState, state, 'reg')} placeholder="ระบุตำบล หรือแขวง"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputRegDistrict" className="col-sm-2 col-form-label">อำเภอ/เขต</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputRegDistrict" value={regDistrict} onChange={inputValue("regDistrict")} onBlur={(e) => cutDistrict(e.target.value, setState, state, 'reg')} placeholder="ระบุ อำเภอ หรือเขต"/>
                </div>
                <label htmlFor="inputRegProvince" className="col-sm-2 col-form-label">จังหวัด</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputRegProvince" value={regProvince} onChange={inputValue("regProvince")} onBlur={(e) => cutProvince(e.target.value, setState, state, 'reg')} placeholder="ระบุ จังหวัด"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputRegZipcode" className="bg-light col-sm-2 col-form-label">รหัสไปรษณีย์</label>
                <div className="col-sm-4 bg-light p-2">
                  <input type="text" className="form-control" id="inputRegZipcode" value={regZipcode} onChange={inputValue("regZipcode")} placeholder="กรอกรหัสไปรษณีย์"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
              <label htmlFor="inputIdHome" className="bg-light col-sm-2 col-form-label">เลขรหัสประจำบ้าน</label>
                <div className="bg-light col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputIdHome" value={regHomeId} onChange={inputValue("regHomeId")} placeholder="เลขรหัสประจำบ้าน"/>
                </div>
                <label htmlFor='inputReligion' className='col-sm-2 col-form-label'>ศาสนา</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputReligion' value={regReligion} onChange={inputValue("regReligion")} placeholder="ระบุศาสนา" />
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputRace' className='col-sm-2 col-form-label'>เชื้อชาติ</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputRace' value={regRace} onChange={inputValue("regRace")} placeholder="ระบุเชื้อชาติ" />
                </div>
                <label htmlFor='inputNationality' className='col-sm-2 col-form-label'>สัญชาติ</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputNationality' value={regNationality} onChange={inputValue("regNationality")} placeholder="ระบุสัญชาติ" />
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputBlood' className='col-sm-2 col-form-label'>หมู่เลือด</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputBlood' value={regBlood} onChange={inputValue("regBlood")} placeholder="ระบุหมู่เลือด ถ้าทราบ"/>
                </div>
                <label htmlFor='inputSyndrom' className='col-sm-2 col-form-label'>โรคประจำตัว</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputSyndrom' value={regSyndrome} onChange={inputValue("regSyndrome")} placeholder="โรคประจำตัว ถ้ามี"/>
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputDrug' className='col-sm-2 col-form-label'>แพ้ยา</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputDrug' value={regDrug} onChange={inputValue("regDrug")} placeholder="การแพ้ยา ถ้ามี"/>
                </div>
                <label htmlFor='inputRegFood' className='col-sm-2 col-form-label'>แพ้อาหาร</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputRegFood' value={regFood} onChange={inputValue("regFood")} placeholder="ระบุอาหารที่แพ้" />
                </div>
              </div>
              <hr className='text-secondary mt-1 mb-3'/>
              <h6 className="text-light bg-secondary fw-bold p-2">- ต้องการสมัครเรียน</h6>
              <hr className='text-secondary mt-1 mb-3'/>
              <div className="row mb-3 ps-1 pe-1">
                <label className="bg-light col-sm-2 col-form-label"><span className='text-danger'>*</span>สมัครเรียนระดับ</label>
                <div className="btn-group col-sm-4 p-2 bg-light" role="group">
                  <input type="radio" className="btn-check" name="chooseClass" id="chooseClass1" value="anubal" checked={regChooseType === 'anubal'} onChange={inputValue("regChooseType")} autoComplete="off" required/>
                  <label className="btn btn-outline-primary" htmlFor="chooseClass1">อนุบาล</label>
                  <input type="radio" className="btn-check" name="chooseClass" id="chooseClass2" value="prathom" checked={regChooseType === 'prathom'} onChange={inputValue("regChooseType")} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="chooseClass2">ประถม</label>
                </div>
                <label htmlFor="inputChooseRoom" className="bg-light col-sm-2 col-form-label"><span className='text-danger'>*</span>ชั้น</label>
                <div className="bg-light col-sm-4 p-2">
                  <select className="form-control" value={regChooseClass} onChange={inputValue("regChooseClass")} id="inputChooseRoom" required disabled={isRegisAcademic}>
                    <option value="">-เลือกชั้นเรียน-</option>
                    {showClassToChoose()}
                  </select>
                </div>
              </div>
              <hr className='text-secondary mt-1 mb-3'/>
              <h6 className="text-light bg-secondary fw-bold p-2">- ประวัติการศึกษา</h6>
              <hr className='text-secondary mt-1 mb-3'/>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputOldSchool" className="bg-light col-sm-2 col-form-label">จากโรงเรียน</label>
                <div className="bg-light col-sm-4 p-2">
                  <input type="text" className="form-control" value={regPrevAcademicSchool} onChange={inputValue("regPrevAcademicSchool")} id="inputOldSchool" placeholder="กรอกชื่อโรงเรียนเดิม" />
                </div>
                <label htmlFor="inputOldAcademicSubdistrict" className="bg-light col-sm-2 col-form-label">ตำบล</label>
                <div className="bg-light col-sm-4 p-2">
                  <input type="text" className="form-control" value={regPrevAcademicSubdistrict} onChange={inputValue("regPrevAcademicSubdistrict")} id="inputOldAcademicSubdistrict" placeholder="ระบุตำบลของโรงเรียนเดิม" />
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputOldSchoolDistrict" className="bg-light col-sm-2 col-form-label">อำเภอ</label>
                <div className="bg-light col-sm-4 p-2">
                  <input type="text" className="form-control" value={regPrevAcademicDistrict} onChange={inputValue("regPrevAcademicDistrict")} id="inputOldSchoolDistrict" placeholder="ระบุอำเภอของโรงเรียนเดิม" />
                </div>
                <label htmlFor="inputOldSchoolProvince" className="bg-light col-sm-2 col-form-label">จังหวัด</label>
                <div className="bg-light col-sm-4 p-2">
                  <input type="text" className="form-control" value={regPrevAcademicProvince} onChange={inputValue("regPrevAcademicProvince")} id="inputOldSchoolProvince" placeholder="ระบุจังหวัดของโรงเรียนเดิม" />
                </div>
              </div>
              <h6 className="text-light bg-secondary fw-bold p-2">- ข้อมูลบิดา/มารดา</h6>
              <hr className='text-secondary mt-1 mb-3'/>
              {/* Dad informaion */}
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputIdCardDad" className="col-sm-2 col-form-label">เลขประจำตัวประชาชน บิดา</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputIdCardDad" ref={inputDadIdCard} value={dadIdCard} onChange={inputValue("dadIdCard")} placeholder="รหัสประจำตัวประชาชน บิดา" />
                </div>
                <div className="col-sm-6 p-2">
                  <button type="button" className="btn btn-primary" onClick={pullDadCard} ><i className="bi bi-person-vcard me-2"></i>ดึงข้อมูลจากบัตรประชาชน</button>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputDadName" className="col-sm-2 col-form-label">บิดา ชื่อ</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputDadName" value={dadName} onChange={inputValue("dadName")} onBlur={(e) => formatName(e.target.value, setState, state, 'dad')} placeholder="ชื่อเต็ม บิดา" />
                </div>
                <label htmlFor="inputDadBirthdate" className="col-sm-2 col-form-label">วัน เดือน ปีเกิด บิดา</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputDadBirthdate" value={dadBirthdate} onChange={inputValue("dadBirthdate")} placeholder="เช่น 31/12/2532" />
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputDadAddress" className="col-sm-2 col-form-label">ที่อยู่</label>
                <div className="col-sm-4 p-2">
                  <textarea type="text" className="form-control" id="inputDadAddress" rows="3" value={dadAddress} onBlur={(e) => reAssignAddress(e.target.value, setState, state, 'dad')} onChange={inputValue("dadAddress")} placeholder="กรอกบ้านเลขที่ ถนน ซอย หมู่"></textarea>
                </div>
                <label htmlFor="inputDadSubDistrict" className="col-sm-2 col-form-label">ตำบล/แขวง</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputDadSubDistrict" value={dadSubDistrict} onChange={inputValue("dadSubDistrict")} onBlur={(e) => cutSubDistrict(e.target.value, setState, state, 'dad')} placeholder="ระบุตำบล หรือแขวง"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputDadDistrict" className="col-sm-2 col-form-label">อำเภอ/เขต</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputDadDistrict" value={dadDistrict} onChange={inputValue("dadDistrict")} onBlur={(e) => cutDistrict(e.target.value, setState, state, 'dad')} placeholder="ระบุ อำเภอ หรือเขต"/>
                </div>
                <label htmlFor="inputDadProvince" className="col-sm-2 col-form-label">จังหวัด</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputDadProvince" value={dadProvince} onChange={inputValue("dadProvince")} onBlur={(e) => cutProvince(e.target.value, setState, state, 'dad')} placeholder="ระบุ จังหวัด"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputDadZipcode" className="bg-light col-sm-2 col-form-label">รหัสไปรษณีย์</label>
                <div className="col-sm-4 bg-light p-2">
                  <input type="text" className="form-control" id="inputDadZipcode" value={dadZipcode} onChange={inputValue("dadZipcode")} placeholder="กรอกรหัสไปรษณีย์"/>
                </div>
              </div>
              <div className="row-mb-3 mb-3 ps-1 pe-1">
                <label className="col-sm-2 col-form-label"></label>
                <div className="btn-group" role="group">
                  <input type="radio" className="btn-check" name="dad_life" id="dad_life1" value={'1'} checked={dadStatus === '1'} onChange={inputValue("dadStatus")} autoComplete="off" />
                  <label className="btn btn-outline-primary" htmlFor="dad_life1">ยังมีชีวิต</label>
                  <input type="radio" className="btn-check" name="dad_life" id="dad_life2" value={'0'} checked={dadStatus === '0'} onChange={inputValue("dadStatus")} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="dad_life2">เสียชีวิต</label>
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputDadRace' className='col-sm-2 col-form-label'>เชื้อชาติ</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputDadRace' value={dadRace} onChange={inputValue("dadRace")} placeholder="ระบุเชื้อชาติของบิดา" />
                </div>
                <label htmlFor='inputDadNationality' className='col-sm-2 col-form-label'>สัญชาติ</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputDadNationality' value={dadNationality} onChange={inputValue("dadNationality")} placeholder="ระบุสัญชาติบิดา" />
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputDadJob' className='bg-light col-sm-2 col-form-label'>อาชีพ</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputDadJob' value={dadJob} onChange={inputValue("dadJob")} placeholder="ระบุอาชีพของบิดา" />
                </div>
                <label htmlFor='inputDadWorkPlace' className='bg-light col-sm-2 col-form-label'>สถานที่ทำงาน</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputDadWorkPlace' value={dadWorkPlace} onChange={inputValue("dadWorkPlace")} placeholder="ระบุสถานที่ทำงานของบิดา" />
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputDadIncome' className='bg-light col-sm-2 col-form-label'>รายได้/เดือน (บาท)</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputDadIncome' value={dadIncome} onChange={inputValue("dadIncome")} placeholder="ระบุรายได้เช่น 15000" />
                </div>
                <label htmlFor='inputDadPhone' className='bg-light col-sm-2 col-form-label'>เบอร์โทรศัพท์</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputDadPhone' value={dadPhone} onChange={inputValue("dadPhone")} placeholder="ระบุเบอรโทรศัพท์ของบิดา" />
                </div>
              </div>
              {/* Mom information */}
              <hr className='text-secondary mt-1 mb-3'/>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputIdCardMom" className="col-sm-2 col-form-label">เลขประจำตัวประชาชน มารดา</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputIdCardMom" ref={inputMomIdCard} value={momIdCard} onChange={inputValue("momIdCard")} placeholder="รหัสประจำตัวประชาชน มาดา" />
                </div>
                <div className="col-sm-6 p-2">
                  <button type="button" className="btn btn-primary" onClick={pullMomCard} ><i className="bi bi-person-vcard me-2"></i>ดึงข้อมูลจากบัตรประชาชน</button>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputMomName" className="col-sm-2 col-form-label">มารดา ชื่อ</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputMomName" value={momName} onChange={inputValue("momName")} onBlur={(e) => formatName(e.target.value, setState, state, 'mom')} placeholder="ชื่อเต็ม มารดา" />
                </div>
                <label htmlFor="inputMomBirthdate" className="col-sm-2 col-form-label">วัน เดือน ปีเกิด มารดา</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputMomBirthdate" value={momBirthdate} onChange={inputValue("momBirthdate")} placeholder="เช่น 31/12/2532" />
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputMomAddress" className="col-sm-2 col-form-label">ที่อยู่</label>
                <div className="col-sm-4 p-2">
                  <textarea type="text" className="form-control" id="inputMomAddress" rows="3" value={momAddress} onChange={inputValue("momAddress")} onBlur={(e) => reAssignAddress(e.target.value, setState, state, 'mom')} placeholder="กรอกบ้านเลขที่ ถนน ซอย หมู่"></textarea>
                </div>
                <label htmlFor="inputMomSubDistrict" className="col-sm-2 col-form-label">ตำบล/แขวง</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputMomSubDistrict" value={momSubDistrict} onChange={inputValue("momSubDistrict")} onBlur={(e) => cutSubDistrict(e.target.value, setState, state, 'mom')} placeholder="ระบุตำบล หรือแขวง"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputMomDistrict" className="col-sm-2 col-form-label">อำเภอ/เขต</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputMomDistrict" value={momDistrict} onChange={inputValue("momDistrict")} onBlur={(e) => cutDistrict(e.target.value, setState, state, 'mom')} placeholder="ระบุ อำเภอ หรือเขต"/>
                </div>
                <label htmlFor="inputMomProvince" className="col-sm-2 col-form-label">จังหวัด</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputMomProvince" value={momProvince} onChange={inputValue("momProvince")} onBlur={(e) => cutProvince(e.target.value, setState, state, 'mom')} placeholder="ระบุ จังหวัด"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputMomZipcode" className="bg-light col-sm-2 col-form-label">รหัสไปรษณีย์</label>
                <div className="col-sm-4 bg-light p-2">
                  <input type="text" className="form-control" id="inputMomZipcode" value={momZipcode} onChange={inputValue("momZipcode")} placeholder="กรอกรหัสไปรษณีย์"/>
                </div>
              </div>
              <div className="row-mb-3 mb-3 ps-1 pe-1">
                <label className="col-sm-2 col-form-label"></label>
                <div className="btn-group" role="group">
                  <input type="radio" className="btn-check" name="mom_life" id="mom_life1" value={'1'} checked={momStatus === '1'} onChange={inputValue("momStatus")} autoComplete="off" />
                  <label className="btn btn-outline-primary" htmlFor="mom_life1">ยังมีชีวิต</label>
                  <input type="radio" className="btn-check" name="mom_life" id="mom_life2" value={'0'} checked={momStatus === '0'} onChange={inputValue("momStatus")} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="mom_life2">เสียชีวิต</label>
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputMomRace' className='col-sm-2 col-form-label'>เชื้อชาติ</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputMomRace' value={momRace} onChange={inputValue("momRace")} placeholder="ระบุเชื้อชาติของมารดา" />
                </div>
                <label htmlFor='inputMomNationality' className='col-sm-2 col-form-label'>สัญชาติ</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputMomNationality' value={momNationality} onChange={inputValue("momNationality")} placeholder="ระบุสัญชาติมารดา" />
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputMomJob' className='bg-light col-sm-2 col-form-label'>อาชีพ</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputMomJob' value={momJob} onChange={inputValue("momJob")} placeholder="ระบุอาชีพของมารดา" />
                </div>
                <label htmlFor='inputMomWorkPlace' className='bg-light col-sm-2 col-form-label'>สถานที่ทำงาน</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputMomWorkPlace' value={momWorkPlace} onChange={inputValue("momWorkPlace")} placeholder="ระบสถานที่ทำงานของมารดา" />
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputMomIncome' className='bg-light col-sm-2 col-form-label'>รายได้/เดือน (บาท)</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputMomIncome' value={momIncome} onChange={inputValue("momIncome")} placeholder="ระบุรายได้เช่น 15000" />
                </div>
                <label htmlFor='inputMomPhone' className='bg-light col-sm-2 col-form-label'>เบอร์โทรศัพท์</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputMomPhone' value={momPhone} onChange={inputValue("momPhone")} placeholder="ระบุเบอรโทรศัพท์ของมารดา" />
                </div>
              </div>
              <hr className='text-secondary mt-1 mb-3'/>
              {/* Parent status */}
              <div className="row mb-3 ps-1 pe-1">
                <label className="bg-light col-sm-2 col-form-label">สถานภาพของบิดา มารดา</label>
                <div className="btn-group col-sm-4 p-2 bg-light" role="group">
                <input type="radio" className="btn-check" name="radio_status" id="radio_status1" value={'togather'} checked={parentStatus === 'togather'} onChange={inputValue("parentStatus")} autoComplete="off" />
                  <label className="btn btn-outline-primary" htmlFor="radio_status1">อยู่ด้วยกัน</label>

                  <input type="radio" className="btn-check" name="radio_status" id="radio_status2" value={'separated'} checked={parentStatus === 'separated'} onChange={inputValue("parentStatus")} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="radio_status2">แยกกันอยู่</label>

                  <input type="radio" className="btn-check" name="radio_status" id="radio_status3" value={'divorce'} checked={parentStatus === 'divorce'} onChange={inputValue("parentStatus")} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="radio_status3">อย่าร้าง</label>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputSibling" className="bg-light col-sm-2 col-form-label">จำนวนพี่น้องทั้งหมด</label>
                <div className="col-sm-4 p-2 bg-light">
                  <input type="text" className="form-control" id="inputSibling" value={regAllChild} onChange={inputValue("regAllChild")} placeholder="ระบุจำนวนพี่น้องทั้งหมด"/>
                </div>
                <label htmlFor="inputChildOrder" className="bg-light col-sm-2 col-form-label">เป็นบุตรคนที่</label>
                <div className="col-sm-4 p-2 bg-light">
                  <input type="text" className="form-control" id="inputChildOrder" value={regChildOrder} onChange={inputValue("regChildOrder")} placeholder="บุตรคนที่"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputSiblingStudying" className="bg-light col-sm-2 col-form-label">จำนวนพี่น้องที่กำลังเรียนอยู่</label>
                <div className="col-sm-4 p-2 bg-light">
                  <input type="text" className="form-control" id="inputSiblingStudying" value={regChildStudying} onChange={inputValue("regChildStudying")} placeholder="ระบุจำนวนพี่น้องที่กำลังเรียนอยู่"/>
                </div>
              </div>
              <h6 className="text-light bg-secondary fw-bold p-2">- กรณีฉุกเฉินสามารถติดต่อเพื่อนบ้านหรือญาติสนิท</h6>
              <hr className='text-secondary mt-1 mb-3'/>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor="inputEmerFname" className="bg-light col-sm-2 col-form-label">ชื่อ</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputEmerFname' value={emerFirstname} onChange={inputValue("emerFirstname")} placeholder='กรุณากรอกชื่อ' />
                </div>
                <label htmlFor="inputEmerLname" className="bg-light col-sm-2 col-form-label">นามสกุล</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputEmerLname' value={emerLastname} onChange={inputValue("emerLastname")} placeholder='กรุณากรอกนามสกุล' />
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor="inputEmerPhone" className="bg-light col-sm-2 col-form-label">เบอร์โทรศัพท์</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputEmerPhone' value={emerPhone} onChange={inputValue("emerPhone")} placeholder='กรุณาเบอร์โทรศัพท์' />
                </div>
              </div>
              <div className="row">
                <div align="right">
                  <button type="submit" className="btn btn-success"><i className="bi bi-check-lg me-2"></i>ยืนยัน</button>
                </div>
              </div>
            </form>
        </div>
        {/* end of box-content rounded */}
      </div>
    </div>
  )

}

export default RegistePrathomComponent