import '../components/print.css'
import { useRef, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import axios from 'axios'
// import Swal from 'sweetalert2'
import logo from '../assets/logo.png'
import rightArrow from '../assets/right-arrow.png'
import LoadingEffect from './LoadingEffect'
import { getToken } from '../services/authorize'
import Swal from 'sweetalert2'

const PrintMatthayomComponent = () => {

  let { st_id } = useParams()
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/listRegister/${st_id}`,
    {
      headers: {
          authorization: `Bearer ${getToken()}`
      }
    })
      .then(response => {
        setData(response.data[0])
        setIsLoading(false)
      })
      .catch(err => {
        // console.log(err)
        Swal.fire({
          title: err.response.statusText,
          text: err.message,
          icon: "error"
        })
      })
  }, [st_id])

  const applicationRef = useRef()
  const printApplication = useReactToPrint({
    content: () => applicationRef.current,
    documentTitle: data.st_idnumber && data.st_name ? data.st_idnumber.join('') +' '+data.st_name.join(' ') + ' ใบสมัคร' : 'ใบสมัครนักเรียน',
    // onAfterPrint: () => {
    //   navigate('/listRegister')
    // }
    // onAfterPrint: () => Swal.fire({
    //   title: "Success",
    //   text: "ปริ้นใบสมัครเรียบร้อย",
    //   icon: "success"
    // })
  })
  const surrenderRef = useRef()
  const printSurrender = useReactToPrint({
    content: () => surrenderRef.current,
    documentTitle: 'ใบมอบตัว',
  })

  return (
    <div className="pt-4 ps-4 pe-4 mt-5 mb-4">
      <div className="container-fluid">
        <div className="row center-scroll-x rounded">
          {/* Display loading icon if data is still being fetched */}
          {isLoading && <LoadingEffect />} 
          <div className='text-center'>
            <button className='btn btn-success mb-2 me-1' onClick={printApplication}>ปริ้นใบสมัคร</button>
            <button className='btn btn-primary mb-2 me-1' onClick={printSurrender}>ปริ้นใบมอบตัว</button>
          </div>
          <div className="a4-layout">
            <div className="print-doc" ref={applicationRef}>
              <center>
                <table>
                  <tbody>
                    <tr>
                      <td rowSpan={2} className="text-center">
                        <img
                          src={logo}
                          width={100}
                          height={100}
                          alt="WTI Logo"
                        />
                      </td>
                      <td colSpan={2} className="text-center">
                        <br />
                        <div
                          className="shadow-sm"
                          style={{
                            border: "solid 2px",
                            padding: "10px",
                            display: "inline-block",
                          }}
                        >
                          <h4 className="fw-bold text-center">
                            ใบสมัครนักเรียนใหม่ ปีการศึกษา 2568
                            <br />
                            โรงเรียนวัฒนธรรมอิสลาม อำเภอปะนาเระ จังหวัดปัตตานี
                          </h4>
                        </div>
                      </td>
                      <td className="text-center p-2" rowSpan={3}>
                        <span
                          className="fs-5 shadow-sm fw-bold mb-1"
                          style={{
                            border: "solid 2px",
                            padding: "5px",
                            display: "inline-block",
                          }}
                        >
                          แผนกมัธยมศึกษา
                        </span>
                        <br />
                        <i className={data.regis_academicStudy === 'junior' ? 'bi bi-check2-circle me-1' : 'bi bi-circle me-1'}></i>
                        <span className="fw-bold fs-6">ม.ต้น</span>
                        <i className={data.regis_academicStudy === 'high' ? 'bi bi-check2-circle me-1 ms-4' : 'bi bi-circle me-1 ms-4'}></i>
                        <span className="fw-bold fs-6">ม.ปลาย</span>
                        <div className="photo-1dot5 text-center fs-5 fw-bold">
                          <br />
                          <br />
                          รูปถ่ายขนาด 1.5 นิ้ว
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="text-center">
                        <span className="fw-bold fs-5">
                          สมัครวันที่<p className='underline'>&emsp;{data.regis_date ? data.regis_date[0] : '\u2003'.repeat(1)}&emsp;</p>
                          เดือน<p className='underline'>&emsp;{data.regis_date ? data.regis_date[1] : '\u2003'.repeat(5)}&emsp;</p>
                          พ.ศ<p className='underline'>&emsp;{data.regis_date ? data.regis_date[2] : '\u2003'.repeat(2)}&emsp;</p>     
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <span className="fw-bold fs-5">
                          -------------------------------------------------------------------------------------------------------------------------
                        </span>
                        <br />
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <i className="bi bi-asterisk me-1"></i>
                                <span className="fw-bold">ผู้สมัคร </span>
                                ชื่อ<p className='underline'>&emsp;{data.st_name ? data.st_name[0] + ' ' + data.st_name[1] : '\u2003'.repeat(7)}&emsp;</p>
                                สกุล<p className='underline'>&emsp;{data.st_name ? data.st_name[2] : '\u2003'.repeat(6)}&emsp;</p>
                                <br />
                                &emsp;&emsp;&emsp;&nbsp;
                                เกิดวันที่<p className='underline'>&emsp;{data.st_birthdate ? data.st_birthdate[0] : '\u2003'.repeat(1)}&emsp;</p>
                                เดือน<p className='underline'>&emsp;{data.st_birthdate ? data.st_birthdate[1] : '\u2003'.repeat(5)}&emsp;</p>
                                พ.ศ<p className='underline'>&emsp;{data.st_birthdate ? data.st_birthdate[2] : '\u2003'.repeat(2)}&emsp;</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <br />
                        &emsp;เลขประจำตัวประชาชน
                      </td>
                      <td colSpan={2}>
                        <br />
                        <span className="box-closed me-2">{data.st_idcard ? data.st_idcard[0] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[1] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[2] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[3] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[4] : ''}</span>
                        <span className="box-closed me-2">{data.st_idcard ? data.st_idcard[5] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[6] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[7] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[8] : ''}</span>
                        <span className="box-closed me-2">{data.st_idcard ? data.st_idcard[9] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[10] : ''}</span>
                        <span className="box-closed me-2">{data.st_idcard ? data.st_idcard[11] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[12] : ''}</span>
                      </td>
                      <td className="text-center">
                        <span className="fw-bold">เลขประจำตัวนักเรียน</span>
                        <br />
                        <span className="box-space">{data.st_idnumber ? data.st_idnumber[0] : ''}</span>
                        <span className="box-space">{data.st_idnumber ? data.st_idnumber[1] : ''}</span>
                        <span className="box-space">{data.st_idnumber ? data.st_idnumber[2] : ''}</span>
                        <span className="box-space">{data.st_idnumber ? data.st_idnumber[3] : ''}</span>
                        <span className="box-space">{data.st_idnumber ? data.st_idnumber[4] : ''}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="pt-1">&emsp;เลขรหัสประจำบ้าน</td>
                      <td className="pt-1" colSpan={2}>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[0] : ''}</span>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[1] : ''}</span>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[2] : ''}</span>
                        <span className="box-closed me-2">{data.st_homeID ? data.st_homeID[3] : ''}</span>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[4] : ''}</span>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[5] : ''}</span>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[6] : ''}</span>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[7] : ''}</span>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[8] : ''}</span>
                        <span className="box-closed me-2">{data.st_homeID ? data.st_homeID[9] : ''}</span>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[10] : ''}</span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        &emsp;
                        {data.st_current && data.st_currentSubDistrict && data.st_currentDistrict && data.st_currentDistrict && data.st_currentProvince ? 
                        (
                          <>
                            ที่อยู่บ้านเลขที่<p className='underline'>&emsp;{data.st_current}&emsp;</p>
                            ตำบล<p className='underline'>&emsp;{data.st_currentSubDistrict}&emsp;</p>
                            อำเภอ<p className='underline'>&emsp;{data.st_currentDistrict}&emsp;</p>
                            จังหวัด<p className='underline'>&emsp;{data.st_currentProvince}&emsp;</p>
                          </>
                        ) : 
                        (
                          <>
                            ที่อยู่บ้านเลขที่<p className='underline'>{'\u2003'.repeat(3)}</p>
                            หมู่ที่<p className='underline'>{'\u2003'.repeat(2)}</p>
                            ถนน<p className='underline'>{'\u2003'.repeat(6)}</p>
                            ตำบล<p className='underline'>&emsp;{data.st_currentSubDistrict ? data.st_currentSubDistrict : '\u2003'.repeat(4)}&emsp;</p>
                            อำเภอ<p className='underline'>&emsp;{data.st_currentDistrict ? data.st_currentDistrict : '\u2003'.repeat(4)}&emsp;</p>
                            จังหวัด<p className='underline'>&emsp;{data.st_currentProvince ? data.st_currentProvince : '\u2003'.repeat(4)}&emsp;</p>
                          </>
                        )} 
                        รหัสไปรษณีย์<p className='underline'>&emsp;{data.st_zipcode ? data.st_zipcode : '\u2003'.repeat(5)}&emsp;</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table-bordered">
                  <tbody>
                    <tr>
                      <td className="text-center p-1" colSpan={2}>
                        <div className="text-left ms-3">
                          <i className="bi bi-asterisk me-1"></i>
                          <span className="fw-bold">บิดา </span>
                          ชื่อ<p className='underline'>&emsp;{data.st_fatherName ? data.st_fatherName[0] + ' ' + data.st_fatherName[1] : '\u2003'.repeat(8)}&emsp;</p>
                          สกุล<p className='underline'>&emsp;{data.st_fatherName ? data.st_fatherName[2] : '\u2003'.repeat(7)}&emsp;</p>
                          <br />
                          &emsp;&emsp;&emsp;
                          เกิดวันที่<p className='underline'>&emsp;{data.st_fatherBirthdate ? data.st_fatherBirthdate[0] : '\u2003'.repeat(1)}&emsp;</p>
                          เดือน<p className='underline'>&emsp;{data.st_fatherBirthdate ? data.st_fatherBirthdate[1] : '\u2003'.repeat(5)}&emsp;</p>
                          พ.ศ<p className='underline'>&emsp;{data.st_fatherBirthdate ? data.st_fatherBirthdate[2] : '\u2003'.repeat(2)}&emsp;</p>
                        </div>
                        <span className="fw-bold">เลขประจำตัวประชาชน</span>
                        <br />
                        <span className="box-closed me-2">{data.st_fatherIdCard ? data.st_fatherIdCard[0] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[1] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[2] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[3] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[4] : ''}</span>
                        <span className="box-closed me-2">{data.st_fatherIdCard ? data.st_fatherIdCard[5] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[6] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[7] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[8] : ''}</span>
                        <span className="box-closed me-2">{data.st_fatherIdCard ? data.st_fatherIdCard[9] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[10] : ''}</span>
                        <span className="box-closed me-2">{data.st_fatherIdCard ? data.st_fatherIdCard[11] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[12] : ''}</span>
                        <div className="mt-2">
                          <i className={data.st_fatherLife === '1' ? 'bi bi-check2-circle me-1' : 'bi bi-circle me-1'}></i>
                          <span className="fw-bold fs-6">ยังมีชีวิตอยู่</span>
                          <i className={data.st_fatherLife === '0' ? 'bi bi-check2-circle me-1 ms-5' : 'bi bi-circle me-1 ms-5'}></i>
                          <span className="fw-bold fs-6">เสียชีวิต</span>
                        </div>
                      </td>
                      <td className="text-center p-1" colSpan={2}>
                        <div className="text-left ms-3">
                          <i className="bi bi-asterisk me-1"></i>
                          <span className="fw-bold">มารดา </span>
                          ชื่อ<p className='underline'>&emsp;{data.st_motherName ? data.st_motherName[0] + ' ' + data.st_motherName[1] : '\u2003'.repeat(8)}&emsp;</p>
                          สกุล<p className='underline'>&emsp;{data.st_motherName ? data.st_motherName[2] : '\u2003'.repeat(7)}&emsp;</p>
                          <br />
                          &emsp;&emsp;&emsp;
                          เกิดวันที่<p className='underline'>&emsp;{data.st_motherBirthdate ? data.st_motherBirthdate[0] : '\u2003'.repeat(1)}&emsp;</p>
                          เดือน<p className='underline'>&emsp;{data.st_motherBirthdate ? data.st_motherBirthdate[1] : '\u2003'.repeat(5)}&emsp;</p>
                          พ.ศ<p className='underline'>&emsp;{data.st_motherBirthdate ? data.st_motherBirthdate[2] : '\u2003'.repeat(2)}&emsp;</p>
                        </div>
                        <span className="fw-bold">เลขประจำตัวประชาชน</span>
                        <br />
                        <span className="box-closed me-2">{data.st_motherIdCard ? data.st_motherIdCard[0] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[1] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[2] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[3] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[4] : ''}</span>
                        <span className="box-closed me-2">{data.st_motherIdCard ? data.st_motherIdCard[5] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[6] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[7] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[8] : ''}</span>
                        <span className="box-closed me-2">{data.st_motherIdCard ? data.st_motherIdCard[9] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[10] : ''}</span>
                        <span className="box-closed me-2">{data.st_motherIdCard ? data.st_motherIdCard[11] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[12] : ''}</span>
                        <div className="mt-2">
                          <i className={data.st_motherLife === '1' ? 'bi bi-check2-circle me-1' : 'bi bi-circle me-1'}></i>
                          <span className="fw-bold fs-6">ยังมีชีวิตอยู่</span>
                          <i className={data.st_motherLife === '0' ? 'bi bi-check2-circle me-1 ms-5' : 'bi bi-circle me-1 ms-5'}></i>
                          <span className="fw-bold fs-6">เสียชีวิต</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="ps-1 pt-1 pe-1 text-left">
                  &emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;
                  <i className="bi bi-asterisk me-2"></i>
                  <span className="fw-bold">สถานภาพสมรสของบิดา มารดา</span>
                  <i className={data.st_parentStatus === 'togather' ? 'bi bi-check2-circle ms-5 me-1' : 'bi bi-circle ms-5 me-1'}></i>
                  <span className="fw-bold fs-6">อยู่ด้วยกัน</span>
                  <i className={data.st_parentStatus === 'separated' ? 'bi bi-check2-circle ms-5 me-1' : 'bi bi-circle ms-5 me-1'}></i>
                  <span className="fw-bold fs-6">แยกกันอยู่</span>
                  <i className={data.st_parentStatus === 'divorce' ? 'bi bi-check2-circle ms-5 me-1' : 'bi bi-circle ms-5 me-1'}></i>
                  <span className="fw-bold fs-6">หย่าร้าง</span>
                </div>
                <div className="text-left">
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                  <i className="bi bi-asterisk me-2"></i>
                  <span className="fw-bold">
                    จำนวนพี่น้องทั้งหมด<p className='underline'>&emsp;{data.st_sibling ? data.st_sibling : '\u2003'.repeat(4)}&emsp;</p>คน&emsp;&emsp;
                    เป็นบุตรคนที่<p className='underline'>&emsp;{data.st_childnumber ? data.st_childnumber : '\u2003'.repeat(4)}&emsp;</p>&emsp;&emsp;
                    จำนวนพี่น้องที่เรียนอยู่<p className='underline'>&emsp;{data.st_studyingSibling ? data.st_studyingSibling : '\u2003'.repeat(4)}&emsp;</p>คน
                  </span>
                </div>
                <div className="text-left">
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                  <i className="bi bi-asterisk me-2"></i>
                  <span className="fw-bold">ผู้ปกครองนักเรียน</span>
                  <i className={data.st_parent === 'dad' ? 'bi bi-check2-circle ms-5 me-1' : 'bi bi-circle ms-5 me-1'}></i>
                  <span className='fw-bold fs-6'>บิดา</span>
                  <i className={data.st_parent === 'mom' ? 'bi bi-check2-circle ms-5 me-1' : 'bi bi-circle ms-5 me-1'}></i>
                  <span className="fw-bold fs-6">มารดา</span>
                  <i className={data.st_parent === 'other' ? 'bi bi-check2-circle ms-5 me-1' : 'bi bi-circle ms-5 me-1'}></i>
                  <span className="fw-bold fs-6">
                    บุคคลอื่น ระบุ<p className='underline'>&emsp;{data.st_parentname ? data.st_parentname : '\u2003'.repeat(7)}&emsp;</p>
                  </span>
                </div>
                <div className="text-left pt-2">
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                  <i className="bi bi-telephone-fill me-2"></i>
                  <span className="fw-bold">เบอร์โทรศัพท์</span>
                  <p className='underline'>
                    &emsp;
                    {data.st_parent === 'dad' ? (
                      data.st_fatherPhone 
                    ) : data.st_parent === 'mom' ? (
                      data.st_motherPhone
                    ) : data.st_parent === 'other' ? (
                      data.st_parentPhone
                    ) : (
                      data.st_parentPhone ? data.st_parentPhone :'\u2003'.repeat(15)
                    )}
                    &emsp;
                  </p>
                </div>
                <div className="text-left ms-4">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src={rightArrow}
                            width={30}
                            height={80}
                            alt="right-arrow"
                          />
                        </td>
                        <td>
                          <i className="bi bi-asterisk me-2"></i>
                          <span className="fw-bold">
                            <u>ประวัติการศึกษา</u>
                          </span>
                          <br />
                          วิชาสามัญจบชั้นสูงสุด ชั้น<p className='underline'>&emsp;{data.st_prevAcademicClass ? data.st_prevAcademicClass : '\u2003'.repeat(5)}&emsp;</p>
                          จากโรงเรียน<p className='underline'>&emsp;{data.st_prevAcademicSchool ? data.st_prevAcademicSchool : '\u2003'.repeat(10)}&emsp;</p>
                          อำเภอ<p className='underline'>&emsp;{data.st_prevAcademicDistrict ? data.st_prevAcademicDistrict : '\u2003'.repeat(5)}&emsp;</p>
                          จังหวัด<p className='underline'>&emsp;{data.st_prevAcademicProvince ? data.st_prevAcademicProvince : '\u2003'.repeat(5)}&emsp;</p>
                          <br />
                          วิชาศาสนาจบชั้นสูงสุด ชั้น<p className='underline'>&emsp;{data.st_prevIslamicClass ? data.st_prevIslamicClass : '\u2003'.repeat(5)}&emsp;</p>
                          จากโรงเรียน<p className='underline'>&emsp;{data.st_prevIslamicSchool ? data.st_prevIslamicSchool : '\u2003'.repeat(10)}&emsp;</p>
                          อำเภอ<p className='underline'>&emsp;{data.st_prevIslamicDistrict ? data.st_prevIslamicDistrict : '\u2003'.repeat(5)}&emsp;</p>
                          จังหวัด<p className='underline'>&emsp;{data.st_prevIslamicProvince ? data.st_prevIslamicProvince : '\u2003'.repeat(5)}&emsp;</p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} className="ps-4">
                          &ensp;&nbsp;
                          <i className="bi bi-asterisk me-2"></i>
                          <span className="fw-bold">
                            <u>โปรแกรมที่สมัครเรียน</u>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table className="table-bordered">
                    <thead>
                      <tr>
                        <td
                          className="text-center fw-bold ps-3 pe-3"
                          colSpan={2}
                        >
                          การศึกษาวิชาสามัญ
                        </td>
                        <td className="text-center fw-bold ps-2 pe-2">
                          การศึกษาวิชาศาสนา
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="ps-2 pe-2">
                          <span className="fw-bold">
                            สมัครเรียนระดับมัธยมศึกษาตอนต้น
                          </span>
                          <br />
                          {data.regis_academicClass && data.regis_academicStudy === 'junior' ? (
                            <>
                              ชั้น <p className="underline">&ensp;{data.regis_academicClass}&ensp;</p>/<p className='underline'>&emsp;&emsp;</p>
                            </>
                          ) : (
                            'ชั้น ม. ............./..............'
                          )}
                          <br />
                          <i className={data.regis_academicProgram === 'simp' && data.regis_academicStudy === 'junior' ? 'bi bi-check2-circle me-1' : 'bi bi-circle me-1'}></i>1. SIMP (Science Intelligent Mathematics Program)
                          <br />
                          &emsp;ความเป็นเลิศด้าน วิทยาศาสตร์ คณิตศาสตร์
                          <br />
                          <i className={data.regis_academicProgram === 'aip' && data.regis_academicStudy === 'junior' ? 'bi bi-check2-circle me-1' : 'bi bi-circle me-1'}></i>2. AIP (Arab Islamic Program) รายวิชาศาสนาเน้นภาษาอาหรับ
                          <br />
                          <i className={data.regis_academicProgram === 'aep' && data.regis_academicStudy === 'junior' ? 'bi bi-check2-circle me-1' : 'bi bi-circle me-1'}></i>3. AEP (Art English Program) เน้นภาษาอังกฤษ  
                          <br />
                          <i className={data.regis_academicProgram === 'asp' && data.regis_academicStudy === 'junior' ? 'bi bi-check2-circle me-1' : 'bi bi-circle me-1'}></i>4. ASP (Art Sports Program) เน้นกีฬา (ฟุตบอลและความถนัดทางกีฬา)
                          <br />
                          <i className={data.regis_academicProgram === 'ap' && data.regis_academicStudy === 'junior' ? 'bi bi-check2-circle me-1' : 'bi bi-circle me-1'}></i>5. AP (Art Program) โปรแกรมทั่วไป 
                          <br />
                          <i className={data.regis_academicProgram === 'stp' && data.regis_academicStudy === 'junior' ? 'bi bi-check2-circle me-1' : 'bi bi-circle me-1'}></i>6. STP (Smart Tahfiz Program) ความเป็นเลิศด้านการท่องอัลกุรอาน 
                        </td>
                        <td className="ps-2 pe-2">
                          <span className="fw-bold">
                            สมัครเรียนระดับมัธยมศึกษาตอนปลาย
                          </span>
                          <br />
                          {data.regis_academicClass && data.regis_academicStudy === 'high' ? (
                            <>
                              ชั้น <p className="underline">&ensp;{data.regis_academicClass}&ensp;</p>/<p className='underline'>&emsp;&emsp;</p>
                            </>
                          ) : (
                            'ชั้น ม. ............./..............'
                          )}
                          <br />
                          <i className={data.regis_academicProgram === 'simp' && data.regis_academicStudy === 'high' ? 'bi bi-check2-circle me-1' : 'bi bi-circle me-1'}></i>1. SIMP (Science Intelligent Mathematics Program)
                          <br />
                          &emsp;ความเป็นเลิศด้าน วิทยาศาสตร์ คณิตศาสตร์
                          <br />
                          <i className={data.regis_academicProgram === 'smp' && data.regis_academicStudy === 'high' ? 'bi bi-check2-circle me-1' : 'bi bi-circle me-1'}></i>2. SMP (Science Mathematics Program) วิทยาศาสตร์ คณิตศาสตร์
                          <br />
                          &emsp;คณิตศาสตร์
                          <br />
                          <i className={data.regis_academicProgram === 'aep' && data.regis_academicStudy === 'high' ? 'bi bi-check2-circle me-1' : 'bi bi-circle me-1'}></i>3. AEP (Art English Program) เน้นภาษาอังกฤษ  
                          <br />
                          <i className={data.regis_academicProgram === 'asp' && data.regis_academicStudy === 'high' ? 'bi bi-check2-circle me-1' : 'bi bi-circle me-1'}></i>4. ASP (Art Sports Program) เน้นกีฬา (ฟุตบอลและความถนัดทางกีฬา)
                          <br />
                          <i className={data.regis_academicProgram === 'ap' && data.regis_academicStudy === 'high' ? 'bi bi-check2-circle me-1' : 'bi bi-circle me-1'}></i>5. AP (Art Program) โปรแกรมทั่วไป 
                        </td>
                        <td className="text-center">
                          ผ่านการทดสอบ ได้ชั้น
                          <br />
                          <br />
                          ....................................
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="text-left mt-1 ms-5">
                  &ensp;
                  <i className="fw-bold">
                    <u>สำหรับเจ้าหน้าที่: </u>ระบุหลักฐานที่ปรากฏในวันรับสมัครและระบุหอพักนักเรียน
                  </i>
                </div>
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <table className="table-bordered">
                            <tbody>
                              <tr>
                                <td className="ps-2 pe-2 text-center">
                                  <span className="fw-bold">
                                    สำเนาบัตรประจำตัวประชาชน
                                  </span>
                                  <br />
                                  <i className="bi bi-circle me-1"></i>นักเรียน
                                  <i className="bi bi-circle ms-1 me-1"></i>บิดา
                                  <i className="bi bi-circle ms-1 me-1"></i>
                                  มารดา
                                  <i className="bi bi-circle ms-1 me-1"></i>
                                  อื่นๆ..........................
                                  <br />
                                  <span className="fw-bold">
                                    สำเนาทะเบียนบ้าน
                                  </span>
                                  <br />
                                  <i className="bi bi-circle me-1"></i>นักเรียน
                                  <i className="bi bi-circle ms-1 me-1"></i>บิดา
                                  <i className="bi bi-circle ms-1 me-1"></i>
                                  มารดา
                                  <i className="bi bi-circle ms-1 me-1"></i>
                                  อื่นๆ..........................
                                  <br />
                                  <div className="text-left ms-5">
                                    <i className="bi bi-circle me-1"></i>
                                    สำเนาระเบียนแสดงผลการเรียน (ปพ. 1)
                                    <br />
                                    <i className="bi bi-circle me-1"></i>
                                    สำเนาใบรับรองผลการเรียน (ปพ. 7)
                                    <br />
                                    <i className="bi bi-circle me-1"></i>
                                    สำเนาซาฮาดะห์ หรือตัสเดกศาสนา (ถ้ามี)
                                    <br />
                                    <i className="bi bi-circle me-1"></i>
                                    รูปถ่ายหน้าตรงขนนาด 1.5 นิ้ว
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>
                          <table className="table-bordered">
                            <tbody>
                              <tr>
                                <td className="fw-bold p-3">
                                  <u>หมายเหตุ</u> หลักฐานการสมัคร
                                  <br />
                                  &emsp;&emsp;&emsp;รูปถ่าย จำนวน 4 รูป&emsp;&emsp;&emsp;
                                  <br />
                                  &emsp;&emsp;&emsp;สำเนาต่างๆ อย่างละ 2 ชุด&emsp;&emsp;&emsp;
                                  <br />
                                  &emsp;&emsp;&emsp;พร้อมเซ็นสำเนาถูกต้อง&emsp;&emsp;&emsp;
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-bold p-2">
                                  เขตหอพัก<p className='underline'>&emsp;{data.dorn_zone ? data.dorn_zone : '\u2003'.repeat(8)}&emsp;</p>
                                  <br />
                                  เลขที่หอพัก<p className='underline'>&emsp;{data.dorn_number ? data.dorn_number : '\u2003'.repeat(8)}&emsp;</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>
                          <table className="table-bordered">
                            <tbody>
                              <tr>
                                <td className="text-center ps-4 pt-3 pe-4 pb-2">
                                  ลงชื่อ.............................................
                                  <br />
                                  (...................................................................)
                                  <br />
                                  ผู้สมัคร
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center ps-4 pt-3 pe-4 pb-2">
                                  ลงชื่อ.............................................
                                  <br />
                                  (...................................................................)
                                  <br />
                                  เจ้าหน้าที่รับสมัคร
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </center>
            </div>
          </div>
          {/*a4-layout end of print application*/}
          {/*a4-layout starty of print surrender application*/}
          <div className='text-center mt-2'>
            <button className='btn btn-success mb-2 me-1' onClick={printApplication}>ปริ้นใบสมัคร</button>
            <button className='btn btn-primary mb-2 me-1' onClick={printSurrender}>ปริ้นใบมอบตัว</button>
          </div>
          <div className="a4-layout">
            <div className="print-doc2" ref={surrenderRef}>
              <center>
                <table>
                  <tbody>
                    <tr>
                      <td>{'\u2003'.repeat(8)}</td>
                      <td className='text-center pt-1 pb-1 ps-4 pe-4'>
                        <img
                          src={logo}
                          width={100}
                          height={100}
                          alt="WTI Logo"
                        />
                        <h4 className="fw-bold text-center mt-1">
                          ใบมอบตัวนักเรียน ปีการศึกษา 2568
                        </h4>
                        <h5 className='fw-bold text-center'>
                          โรงเรียนวัฒนธรรมอิสลาม ตำบลพ่อมิ่ง อำเภอปะนาเระ จังหวัดปัตตานี
                        </h5>
                      </td>
                      <td className='p-1' rowSpan={2}>
                        <div className="photo-bt-label p-1 fw-bold">
                          <br/><br/><br/><br/>
                          <span className='ms-4'>ติดรูปถ่าย ขนาด 1.5 นิ้ว</span>
                          <br/><br/><br/>
                          เลขประจำตัวนักเรียน<p className='underline mt-2'>&ensp;{data.st_id ? data.st_idnumber.join('') : '\u2003'.repeat(2)}&emsp;</p>
                          <br />
                          ชั้นเรียนสามัญ<p className='underline'>&ensp;{data.regis_academicClass ? data.regis_academicClass + '\u2002/\u2003\u2003' : '\u2003'.repeat(4)}&ensp;&nbsp;</p>
                          <br />
                          ชั้นเรียนศาสนา<p className='underline'>&ensp;{'\u2003'.repeat(4)}&ensp;</p> 
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className='p-1'>
                        <u className='fw-bold fs-6'>ข้อมูลนักเรียน</u>
                        <br/>
                        {data.st_name ? (
                        <>
                          1. ชื่อ<p className='underline'>&emsp;{data.st_name[0] + ' ' + data.st_name[1]}&emsp;</p>
                          นามสกุล<p className='underline'>&emsp;{data.st_name[2]}&emsp;</p>
                        </>
                        ) : (
                        <>
                        (ด.ช./ด.ญ./นาย/นางสาว)<p className='underline'>&emsp;{'\u2003'.repeat(11)}&emsp;</p>
                        นามสกุล<p className='underline'>&emsp;{'\u2003'.repeat(11)}&emsp;</p>
                        </>
                        )}
                        <br/>
                        <span className="me-1">เลขประจำตัวประชาชน</span>
                        <span className="box-closed me-2">{data.st_idcard ? data.st_idcard[0] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[1] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[2] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[3] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[4] : ''}</span>
                        <span className="box-closed me-2">{data.st_idcard ? data.st_idcard[5] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[6] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[7] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[8] : ''}</span>
                        <span className="box-closed me-2">{data.st_idcard ? data.st_idcard[9] : ''}</span>
                        <span className="box-closed">{data.st_idcard ? data.st_idcard[10] : ''}</span>
                        <span className="box-closed me-2">{data.st_idcard ? data.st_idcard[11] : ''}</span>
                        <span className="box-closed me-1">{data.st_idcard ? data.st_idcard[12] : ''}</span>
                        ศาสนา<p className='underline'>&emsp;{data.st_religion ? data.st_religion : '\u2003'.repeat(5)}&emsp;</p>
                        <br />
                        เกิดวันที่<p className='underline'>&emsp;{data.st_birthdate ? data.st_birthdate[0] : '\u2003'.repeat(1)}&emsp;</p>
                        เดือน<p className='underline'>&emsp;{data.st_birthdate ? data.st_birthdate[1] : '\u2003'.repeat(5)}&emsp;</p>
                        พ.ศ<p className='underline'>&emsp;{data.st_birthdate ? data.st_birthdate[2] : '\u2003'.repeat(2)}&emsp;</p>
                        เชื้อชาติ<p className='underline'>&emsp;{data.st_race ? data.st_race : '\u2003'.repeat(2)}&emsp;</p>
                        สัญชาติ<p className='underline'>&emsp;{data.st_nationality ? data.st_nationality : '\u2003'.repeat(2)}&emsp;</p>
                        หมู่เลือด<p className='underline'>&emsp;{data.st_blood ? data.st_blood : '\u2003'.repeat(1)}&emsp;</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} className='p-1'>
                        2. ที่อยู่ตามสำเนาทะเบียนบ้าน เลขรหัสประจำบ้าน &emsp;
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[0] : ''}</span>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[1] : ''}</span>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[2] : ''}</span>
                        <span className="box-closed me-2">{data.st_homeID ? data.st_homeID[3] : ''}</span>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[4] : ''}</span>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[5] : ''}</span>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[6] : ''}</span>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[7] : ''}</span>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[8] : ''}</span>
                        <span className="box-closed me-2">{data.st_homeID ? data.st_homeID[9] : ''}</span>
                        <span className="box-closed">{data.st_homeID ? data.st_homeID[10] : ''}</span>
                        <br/>
                        {data.st_current ? 
                        (
                          <>
                            บ้านเลขที่<p className='underline'>&emsp;{data.st_current}&emsp;</p>
                            ตำบล<p className='underline'>&emsp;{data.st_currentSubDistrict}&emsp;</p>
                            อำเภอ<p className='underline'>&emsp;{data.st_currentDistrict}&emsp;</p>
                            จังหวัด<p className='underline'>&emsp;{data.st_currentProvince}&emsp;</p>
                          </>
                        ) : 
                        (
                          <>
                            บ้านเลขที่<p className='underline'>{'\u2003'.repeat(3)}</p>
                            หมู่ที่<p className='underline'>{'\u2003'.repeat(2)}</p>
                            ถนน<p className='underline'>{'\u2003'.repeat(7)}</p>
                            ตำบล<p className='underline'>{'\u2003'.repeat(6)}</p>
                            อำเภอ<p className='underline'>{'\u2003'.repeat(6)}</p>
                            จังหวัด<p className='underline'>{'\u2003'.repeat(6)}</p>
                          </>
                        )} 
                        รหัสไปรษณีย์<p className='underline'>&emsp;{data.st_zipcode ? data.st_zipcode : '\u2003'.repeat(2)}&emsp;</p>
                        <br/>
                        3. มีพี่น้อง<p className='underline'>&emsp;{data.st_sibling ? data.st_sibling : ''}&emsp;</p>คน&nbsp;
                        เป็นบุตรคนที่<p className='underline'>&emsp;{data.st_childnumber ? data.st_childnumber : ''}&emsp;</p>คน&nbsp;
                        จำนวนพี่น้องที่เรียนอยู่<p className='underline'>&emsp;{data.st_studyingSibling ? data.st_studyingSibling : ''}&emsp;</p>คน&nbsp;
                        โรคประจำตัว<p className='underline'>&emsp;{data.st_syndrome ? data.st_syndrome : '\u2003'.repeat(5)}&emsp;</p>
                        แพ้ยา<p className='underline'>&emsp;{data.st_drug ? data.st_drug : '\u2003'.repeat(4)}&emsp;</p>   
                        แพ้อาหาร<p className='underline'>&emsp;{data.st_food ? data.st_food : '\u2003'.repeat(3)}&emsp;</p>  
                        <br/>
                        4. โรงเรียนเดิมชื่อ<p className='underline'>&emsp;{data.st_prevAcademicSchool ? data.st_prevAcademicSchool : '\u2003'.repeat(12)}&emsp;</p>
                        ตำบล<p className='underline'>&emsp;{data.st_prevAcademicSubDistrict ? data.st_prevAcademicSubDistrict : '\u2003'.repeat(6)}&emsp;</p>
                        อำเภอ<p className='underline'>&emsp;{data.st_prevAcademicDistrict ? data.st_prevAcademicDistrict : '\u2003'.repeat(6)}&emsp;</p>
                        จังหวัด<p className='underline'>&emsp;{data.st_prevAcademicProvince ? data.st_prevAcademicProvince : '\u2003'.repeat(6)}&emsp;</p> 
                        <br/>
                        5. สถานภาพสมรสของบิดา มารดา
                        <i className={data.st_parentStatus === 'togather' ? 'bi bi-check2-square ms-3 me-1' : 'bi bi-square ms-3 me-1'}></i>
                        อยู่ด้วยกัน
                        <i className={data.st_parentStatus === 'separated' ? 'bi bi-check2-square ms-3 me-1' : 'bi bi-square ms-3 me-1'}></i>
                        แยกกันอยู่
                        <i className={data.st_parentStatus === 'divorce' ? 'bi bi-check2-square ms-3 me-1' : 'bi bi-square ms-3 me-1'}></i>
                        หย่าร้าง
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1' colSpan={3}>
                        <u className='fw-bold fs-6'>ข้อมูลบิดา/มารดา</u>
                        <br/>
                        1. ชื่อ-สกุล บิดา<p className='underline'>&emsp;{data.st_fatherName ? data.st_fatherName.join(' '): '\u2003'.repeat(11)}&emsp;</p>
                        <span className="me-1">เลขประจำตัวประชาชน</span>
                        <span className="box-closed me-2">{data.st_fatherIdCard ? data.st_fatherIdCard[0] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[1] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[2] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[3] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[4] : ''}</span>
                        <span className="box-closed me-2">{data.st_fatherIdCard ? data.st_fatherIdCard[5] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[6] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[7] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[8] : ''}</span>
                        <span className="box-closed me-2">{data.st_fatherIdCard ? data.st_fatherIdCard[9] : ''}</span>
                        <span className="box-closed">{data.st_fatherIdCard ? data.st_fatherIdCard[10] : ''}</span>
                        <span className="box-closed me-2">{data.st_fatherIdCard ? data.st_fatherIdCard[11] : ''}</span>
                        <span className="box-closed me-1">{data.st_fatherIdCard ? data.st_fatherIdCard[12] : ''}</span>
                        <br />
                        เกิดวันที่<p className='underline mt-1'>&emsp;{data.st_fatherBirthdate ? data.st_fatherBirthdate[0] : '\u2003'.repeat(2)}&emsp;</p>
                        เดือน<p className='underline'>&emsp;{data.st_fatherBirthdate ? data.st_fatherBirthdate[1] : '\u2003'.repeat(9)}&emsp;</p>
                        พ.ศ<p className='underline'>&emsp;{data.st_fatherBirthdate ? data.st_fatherBirthdate[2] : '\u2003'.repeat(3)}&emsp;</p>
                        เชื้อชาติ<p className='underline'>&emsp;{data.st_fatherRace ? data.st_fatherRace : '\u2003'.repeat(3)}&emsp;</p>
                        สัญชาติ<p className='underline'>&emsp;{data.st_fatherNationality ? data.st_fatherNationality : '\u2003'.repeat(3)}&emsp;</p>
                        <i className={data.st_fatherLife === '1' ? 'bi bi-check2-circle me-1' : 'bi bi-circle me-1'}></i>ยังมีชีวิตอยู่
                        <i className={data.st_fatherLife === '0' ? 'bi bi-check2-circle me-1 ms-2' : 'bi bi-circle me-1 ms-2'}></i>เสียชีวิต
                        <br/>
                        อาชีพ<p className='underline'>&emsp;{data.st_fatherJob ? data.st_fatherJob : '\u2003'.repeat(8)}&emsp;</p>
                        สถานที่ทำงาน<p className='underline'>&emsp;{data.st_fatherWorkPlace ? data.st_fatherWorkPlace : '\u2003'.repeat(8)}&emsp;</p>
                        รายได้ / เดือน<p className='underline'>&emsp;{data.st_fatherAnnualIncome ? data.st_fatherAnnualIncome : '\u2003'.repeat(4)}&emsp;</p>บาท&nbsp;
                        เบอร์โทรศัพท์<p className='underline'>&emsp;{data.st_fatherPhone ? data.st_fatherPhone : '\u2003'.repeat(5)}&emsp;</p>
                        <br/>
                        2. ชื่อ-สกุล มารดา<p className='underline'>&emsp;{data.st_motherName ? data.st_motherName.join(' '): '\u2003'.repeat(11)}&emsp;</p>
                        <span className="me-1">เลขประจำตัวประชาชน</span>
                        <span className="box-closed me-2">{data.st_motherIdCard ? data.st_motherIdCard[0] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[1] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[2] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[3] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[4] : ''}</span>
                        <span className="box-closed me-2">{data.st_motherIdCard ? data.st_motherIdCard[5] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[6] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[7] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[8] : ''}</span>
                        <span className="box-closed me-2">{data.st_motherIdCard ? data.st_motherIdCard[9] : ''}</span>
                        <span className="box-closed">{data.st_motherIdCard ? data.st_motherIdCard[10] : ''}</span>
                        <span className="box-closed me-2">{data.st_motherIdCard ? data.st_motherIdCard[11] : ''}</span>
                        <span className="box-closed me-1">{data.st_motherIdCard ? data.st_motherIdCard[12] : ''}</span>
                        <br />
                        เกิดวันที่<p className='underline mt-1'>&emsp;{data.st_motherBirthdate ? data.st_motherBirthdate[0] : '\u2003'.repeat(2)}&emsp;</p>
                        เดือน<p className='underline'>&emsp;{data.st_motherBirthdate ? data.st_motherBirthdate[1] : '\u2003'.repeat(9)}&emsp;</p>
                        พ.ศ<p className='underline'>&emsp;{data.st_motherBirthdate ? data.st_motherBirthdate[2] : '\u2003'.repeat(3)}&emsp;</p>
                        เชื้อชาติ<p className='underline'>&emsp;{data.st_motherRace ? data.st_motherRace : '\u2003'.repeat(3)}&emsp;</p>
                        สัญชาติ<p className='underline'>&emsp;{data.st_motherNationality ? data.st_motherNationality : '\u2003'.repeat(3)}&emsp;</p>
                        <i className={data.st_motherLife === '1' ? 'bi bi-check2-circle me-1' : 'bi bi-circle me-1'}></i>ยังมีชีวิตอยู่
                        <i className={data.st_motherLife === '0' ? 'bi bi-check2-circle me-1 ms-2' : 'bi bi-circle me-1 ms-2'}></i>เสียชีวิต
                        <br/>
                        อาชีพ<p className='underline'>&emsp;{data.st_motherJob ? data.st_motherJob : '\u2003'.repeat(8)}&emsp;</p>
                        สถานที่ทำงาน<p className='underline'>&emsp;{data.st_motherWorkPlace ? data.st_motherWorkPlace : '\u2003'.repeat(8)}&emsp;</p>
                        รายได้ / เดือน<p className='underline'>&emsp;{data.st_motherAnnualIncome ? data.st_motherAnnualIncome : '\u2003'.repeat(4)}&emsp;</p>บาท&nbsp;
                        เบอร์โทรศัพท์<p className='underline'>&emsp;{data.st_motherPhone ? data.st_motherPhone : '\u2003'.repeat(5)}&emsp;</p>
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1' colSpan={3}>
                        <u className='fw-bold'>ที่อยู่ปัจจุบันของนักเรียน (กรณีไม่ได้อยู่กับบิดา/มารดา)</u>
                        <br/>
                        ชื่อ<p className='underline'>&emsp;{data.st_parent === 'other' && data.st_parentname ? data.st_parentname[0] + ' ' + data.st_parentname[1] : '\u2003'.repeat(8)}&emsp;</p>
                        นามสกุล<p className='underline'>&emsp;{data.st_parent === 'other' && data.st_parentname ? data.st_parentname[2] : '\u2003'.repeat(7)}&emsp;</p>
                        เกี่ยวข้องกับนักเรียนเป็น<p className='underline'>&emsp;{data.st_parent === 'other' && data.st_parentRelation ? data.st_parentRelation : '\u2003'.repeat(5)}&emsp;</p>
                        <br/>
                        {data.st_parent === 'other' && data.st_parentAddress ? 
                        (
                          <>
                            ที่อยู่บ้านเลขที่<p className='underline'>&emsp;{data.st_parentAddress}&emsp;</p>
                            ตำบล<p className='underline'>&emsp;{data.st_parentSubDistrict}&emsp;</p>
                            อำเภอ<p className='underline'>&emsp;{data.st_parentDistrict}&emsp;</p>
                            จังหวัด<p className='underline'>&emsp;{data.st_parentProvince}&emsp;</p>
                          </>
                        ) : 
                        (
                          <>
                            ที่อยู่บ้านเลขที่<p className='underline'>{'\u2003'.repeat(2)}</p>
                            หมู่ที่<p className='underline'>{'\u2003'.repeat(2)}</p>
                            ถนน<p className='underline'>{'\u2003'.repeat(7)}</p>
                            ตำบล<p className='underline'>{'\u2003'.repeat(6)}</p>
                            อำเภอ<p className='underline'>{'\u2003'.repeat(6)}</p>
                            จังหวัด<p className='underline'>{'\u2003'.repeat(6)}</p>
                          </>
                        )} 
                        รหัสไปรษณีย์<p className='underline'>&emsp;{data.st_parent === 'other' && data.st_parentZipcode ? data.st_parentZipcode : '\u2000'.repeat(3)}&emsp;</p>
                        <br/>
                        อาชีพ<p className='underline'>&emsp;{data.st_parent === 'other' && data.st_parentjob ? data.st_parentjob : '\u2003'.repeat(8)}&emsp;</p>
                        สถานที่ทำงาน<p className='underline'>&emsp;{data.st_parent === 'other' && data.st_parentWorkPlace ? data.st_parentWorkPlace : '\u2003'.repeat(8)}&emsp;</p>
                        รายได้ / เดือน<p className='underline'>&emsp;{data.st_parent === 'other' && data.st_parentannualincome ? data.st_parentannualincome : '\u2003'.repeat(3)}&emsp;</p>บาท&nbsp;
                        เบอร์โทรศัพท์<p className='underline'>&emsp;{data.st_parent === 'other' && data.st_parentPhone ? data.st_parentPhone : '\u2003'.repeat(6)}&emsp;</p>
                      </td>
                    </tr>
                    <tr>
                      <td className='p-1' colSpan={3}>
                        <u className='fw-bold'>กรณีฉุกเฉินสามารถติดต่อเพื่อนบ้านหรือญาติสนิท</u>
                        <br/>
                        ชื่อ<p className='underline'>&emsp;{data.st_emergencyFirstName ? data.st_emergencyFirstName : '\u2003'.repeat(12)}&emsp;</p>
                        นามสกุล<p className='underline'>&emsp;{data.st_emergencyLastName ? data.st_emergencyLastName : '\u2003'.repeat(12)}&emsp;</p>
                        เบอร์โทรศัพท์<p className='underline'>&emsp;{data.st_emergencyPhone ? data.st_emergencyPhone : '\u2003'.repeat(10)}&emsp;</p>
                      </td>
                    </tr>
                    <tr>
                      <td className='pt-3' colSpan={3}>
                        {'\u2003'.repeat(5)}ขอรับรองว่าผู้สมัครเป็นผู้ที่มีความประพฤติเรียบร้อยและมีคุณสมบัติอื่นๆ ตามข้อบังคับของโรงเรียนทุกประการ ข้าพเจ้ายินดีปฏิบัติตามกฎระเบียบ ข้อบังคับ
                        <br/>การบริหารจัดการ ตลอดจนค่าใช้จ่ายต่างๆ ที่โรงเรียนกำหนด พร้อมทั้งให้ความร่วมมือกับโรงเรียนทุกประการจนพ้นสภาพการเป็นนักเรียน
                      </td>
                    </tr>
                    <tr>
                      <td className='pt-5' colSpan={3}>
                      {'\u2003'.repeat(24)}ลงชื่อ.......................................................ผู้ปกครอง
                      <br/>
                      {'\u2003'.repeat(25)}(..............................................................)
                      <br/>
                      {'\u2003'.repeat(22)}วันที่ ...............เดือน ....................................พ.ศ. ........................
                      <br/><br/>
                      {'\u2003'.repeat(24)}ลงชื่อ........................................................ผู้อำนวยการโรงเรียน
                      <br/>
                      {'\u2003'.repeat(27)}(นายโมฮำหมัด  กือแต)
                      <br/>
                      {'\u2003'.repeat(22)}วันที่ ...............เดือน ......................................พ.ศ. .........................
                      
                      </td>
                    </tr>
                  </tbody>
                </table>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrintMatthayomComponent